import {
  ShoppingIcon,
  LuggageIcon,
  HospitalIcon,
  JusticeIcon,
  ProgrammerIcon,
  PuzzleIcon,
} from "../icons"

export default {
  quemservimos: [
    { text: "Ecommerce", icon: ShoppingIcon, link: "/quem-servimos/ecommerce" },
    { text: "Pequenas Empresas", icon: LuggageIcon, link: "/quem-servimos/pequena-empresa" },
    { text: "Profissionais Liberais", icon: ProgrammerIcon, link: "/quem-servimos/profissional-liberal" },
    { text: "Instituições em Geral", icon: PuzzleIcon, link: "/quem-servimos/instituicoes" },
    { text: "Clínicas em Geral", icon: HospitalIcon, link: "/quem-servimos/clinicas" },
    { text: "Advogados e Escritórios", icon: JusticeIcon, link: "/quem-servimos/escritorio-de-advocacia" },
  ],
  servicos: [
    { text: "Loja Virtual", link: "/servicos/loja-virtual" },
    { text: "Marketing", link: "/servicos/marketing" },
    { text: "Website", link: "/servicos/website" },
  ]
}
