import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;

  p {
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }
`
