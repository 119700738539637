import React from "react"

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <g>
      <g>
        <path d="m488.5 465h-68v-305.365c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v17.264h-299v-73.899h87v9.345c0 6.341 5.159 11.5 11.5 11.5h23.5v23.5c0 6.341 5.159 11.5 11.5 11.5h32c6.341 0 11.5-5.159 11.5-11.5v-23.5h23.5c6.341 0 11.5-5.159 11.5-11.5v-9.345h87v24.567c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-32.067c0-4.143-3.358-7.5-7.5-7.5h-53.5v-80.5c0-4.143-3.358-7.5-7.5-7.5h-129.247c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h121.747v73h-26v-7.655c0-6.341-5.159-11.5-11.5-11.5h-23.5v-23.5c0-6.341-5.159-11.5-11.5-11.5h-32c-6.341 0-11.5 5.159-11.5 11.5v23.5h-23.5c-6.341 0-11.5 5.159-11.5 11.5v7.655h-26v-73h24.37c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-31.87c-4.142 0-7.5 3.357-7.5 7.5v80.5h-53.5c-4.142 0-7.5 3.357-7.5 7.5v369.5h-68c-12.958 0-23.5 10.542-23.5 23.5s10.542 23.5 23.5 23.5h71.998c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-71.998c-4.687 0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5h465c4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5h-360.258c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h360.258c12.958 0 23.5-10.542 23.5-23.5s-10.542-23.5-23.5-23.5zm-280-381.155h23.5c6.341 0 11.5-5.159 11.5-11.5v-23.5h25v23.5c0 6.341 5.159 11.5 11.5 11.5h23.5v25h-23.5c-6.341 0-11.5 5.159-11.5 11.5v23.5h-25v-23.5c0-6.341-5.159-11.5-11.5-11.5h-23.5zm196.855 108.053v15h-298.855v-15zm-232.855 273.102v-45h19v45zm133-45v45h-99v-45zm15 45v-45h19v45zm34 0v-52.5c0-4.143-3.358-7.5-7.5-7.5h-182c-4.142 0-7.5 3.357-7.5 7.5v52.5h-51v-243.102h299v243.102z"></path>
        <path d="m190 234.5h-54c-4.142 0-7.5 3.357-7.5 7.5v50c0 4.143 3.358 7.5 7.5 7.5h54c4.142 0 7.5-3.357 7.5-7.5v-50c0-4.143-3.358-7.5-7.5-7.5zm-7.5 50h-39v-35h39z"></path>
        <path d="m376 234.5h-54c-4.142 0-7.5 3.357-7.5 7.5v50c0 4.143 3.358 7.5 7.5 7.5h54c4.142 0 7.5-3.357 7.5-7.5v-50c0-4.143-3.358-7.5-7.5-7.5zm-7.5 50h-39v-35h39z"></path>
        <path d="m283 234.5h-54c-4.142 0-7.5 3.357-7.5 7.5v50c0 4.143 3.358 7.5 7.5 7.5h54c4.142 0 7.5-3.357 7.5-7.5v-50c0-4.143-3.358-7.5-7.5-7.5zm-7.5 50h-39v-35h39z"></path>
        <path d="m190 321.5h-54c-4.142 0-7.5 3.357-7.5 7.5v50c0 4.143 3.358 7.5 7.5 7.5h54c4.142 0 7.5-3.357 7.5-7.5v-50c0-4.143-3.358-7.5-7.5-7.5zm-7.5 50h-39v-35h39z"></path>
        <path d="m376 321.5h-54c-4.142 0-7.5 3.357-7.5 7.5v50c0 4.143 3.358 7.5 7.5 7.5h54c4.142 0 7.5-3.357 7.5-7.5v-50c0-4.143-3.358-7.5-7.5-7.5zm-7.5 50h-39v-35h39z"></path>
        <path d="m283 321.5h-54c-4.142 0-7.5 3.357-7.5 7.5v50c0 4.143 3.358 7.5 7.5 7.5h54c4.142 0 7.5-3.357 7.5-7.5v-50c0-4.143-3.358-7.5-7.5-7.5zm-7.5 50h-39v-35h39z"></path>
      </g>
    </g>
  </svg>
)
