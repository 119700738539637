import React from "react"
import { IconType } from "react-icons"
import { Link } from "gatsby"

import { Container, IconTextStyled } from "./styles"

interface SelectIconSectionProps {
  description: string
  elements: ITextIconElements[]
}

export interface ITextIconElements {
  icon: IconType
  text: string
  link?: string
}

const SelectIconSection = ({
  elements,
  description,
}: SelectIconSectionProps) => {
  return (
    <Container>
      <div className="section">
        <h2
          data-sal="slide-right"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          {description}
        </h2>
        {elements.map((element, index) => (
          <Link key={index} to={element.link || "#"}>
            <IconTextStyled text={element.text}>
              <element.icon />
            </IconTextStyled>
          </Link>
        ))}
      </div>
    </Container>
  )
}

export default SelectIconSection
