import React from "react"

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <g id="_x31_0_briefcase_2_">
      <path d="m0 437.007c0 23.435 19.065 42.5 42.5 42.5h42.414c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-42.414c-15.164 0-27.5-12.336-27.5-27.5v-143.328c7.419 6.313 17.019 10.134 27.5 10.134h169.673v31.658c0 9.649 7.851 17.5 17.5 17.5h52.654c9.65 0 17.5-7.851 17.5-17.5v-31.658h84.879c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-342.206c-15.164 0-27.5-12.336-27.5-27.5v-105.694c0-15.163 12.336-27.5 27.5-27.5h427c15.164 0 27.5 12.337 27.5 27.5v105.694c0 15.164-12.336 27.5-27.5 27.5h-51.906c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h51.906c10.481 0 20.082-3.822 27.5-10.134v143.328c0 15.164-12.336 27.5-27.5 27.5h-351.698c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h351.698c23.435 0 42.5-19.065 42.5-42.5v-281.388c0-23.434-19.065-42.5-42.5-42.5h-102.422v-35.626c0-24.813-20.186-45-45-45h-76.684c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h76.684c16.542 0 30 13.458 30 30v35.626h-20v-25.626c0-11.028-8.972-20-20-20h-112.156c-11.028 0-20 8.972-20 20v25.626h-20v-35.626c0-16.542 13.458-30 30-30h22.585c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-22.585c-24.814 0-45 20.187-45 45v35.626h-102.422c-23.435 0-42.5 19.065-42.5 42.5zm284.827-133.194v31.658c0 1.378-1.121 2.5-2.5 2.5h-52.654c-1.379 0-2.5-1.122-2.5-2.5v-31.658zm-89.905-216.32c0-2.757 2.243-5 5-5h112.156c2.757 0 5 2.243 5 5v25.626h-122.156z"></path>
      <circle cx="256" cy="320.892" r="7.5"></circle>
      <path d="m451.345 445.638c9.649 0 17.5-7.851 17.5-17.5v-23.924c0-9.649-7.851-17.5-17.5-17.5h-81.767c-9.649 0-17.5 7.851-17.5 17.5v23.924c0 9.649 7.851 17.5 17.5 17.5zm2.5-41.424v23.924c0 1.378-1.121 2.5-2.5 2.5h-39.004l13.17-28.924h25.834c1.379 0 2.5 1.121 2.5 2.5zm-86.767 23.924v-23.924c0-1.379 1.121-2.5 2.5-2.5h39.45l-13.17 28.924h-26.28c-1.379 0-2.5-1.122-2.5-2.5z"></path>
    </g>
  </svg>
)
