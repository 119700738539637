import React from "react"

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <g>
      <circle cx="256" cy="406" r="10"></circle>
      <path d="m326 166v-120c0-5.523-4.478-10-10-10h-90c-38.598 0-70 31.402-70 70v6.91c0 3.22 1.551 6.243 4.165 8.122 2.621 1.882 5.981 2.381 9.026 1.355 5.49-1.849 11.113-3.298 16.809-4.345v47.958c0 38.598 31.401 70 70 70s70-31.402 70-70zm-149.592-66.401c3.153-24.558 24.191-43.599 49.592-43.609h78.995c-4.645 22.804-24.849 40.01-48.995 40.01h-47.58c-11.107 0-21.994 1.307-32.012 3.599zm79.592 116.401c-27.57 0-50-22.43-50-50v-49.972c.805-.016 50-.028 50-.028 19.572 0 37.284-8.084 50-21.077v71.077c0 27.57-22.43 50-50 50z"></path>
      <path d="m457 396c-6.177 0-12.233 1.053-17.95 3.029l12.651-50.604c.747-2.988.076-6.152-1.819-8.58-1.895-2.427-4.803-3.845-7.882-3.845h-41c0-55.14-44.859-100-100-100h-90c-55.141 0-100 44.86-100 100h-41c-3.079 0-5.987 1.418-7.882 3.846-1.896 2.427-2.566 5.592-1.819 8.58l12.651 50.604c-5.717-1.977-11.773-3.03-17.95-3.03-30.327 0-55 24.673-55 55v15c0 5.523 4.478 10 10 10h201c5.522 0 10-4.477 10-10s-4.478-10-10-10h-103.192l-25-100h346.385l-25 100h-103.193c-5.522 0-10 4.477-10 10s4.478 10 10 10h201c5.522 0 10-4.477 10-10v-15c0-30.327-24.673-55-55-55zm-369.808 60h-67.192v-5c0-19.299 15.701-35 35-35 9.315 0 18.214 3.725 24.763 10.281zm133.808-200h70v5c0 19.299-15.701 35-35 35s-35-15.701-35-35zm-90 80c0-40.724 30.594-74.413 70-79.353v4.353c0 30.327 24.673 55 55 55s55-24.673 55-55v-4.353c39.406 4.939 70 38.629 70 79.353zm361 120h-67.192l7.43-29.719c6.548-6.556 15.447-10.281 24.762-10.281 19.299 0 35 15.701 35 35z"></path>
      <circle cx="256" cy="466" r="10"></circle>
    </g>
  </svg>
)
