import styled from "styled-components"

import IconText from "../../Atoms/IconText"
import myTheme from "../../../theme"

export const Container = styled.div`
  background-color: ${myTheme.colors.secondaryBG};

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    max-width: 1120px;
    margin: auto;
    overflow: hidden;

    background-color: #f4f4f4;

    a p {
      color: black;
    }

    h2 {
      font-size: 24px;
      line-height: 1.2em;
      font-weight: 800;
      width: 140px;

      @media (max-width: 1100px) {
        width: 100%;
        margin: 40px 0 10px 40px;
      }
    }
  }
`

export const IconTextStyled = styled(IconText)`
  width: 150px;
  height: 150px;

  svg {
    width: 32px;
    height: 50px;
  }

  &:hover {
    background-color: #fff;
    cursor: pointer;
    border-bottom: solid 7px ${myTheme.colors.primary};
  }
`
