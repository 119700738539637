import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../templates/main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"
import FeatureSideImageSection from "../components/Organisms/FeatureSideImageSection"
import LogoCompaniesSection from "../components/Organisms/LogoCompaniesSection"
import ContactSection from "../components/Organisms/ContactSection"

import SelectIconSection from "../components/Organisms/SelectIconSection"
import data from "../data/menu"

const IndexPage = () => {
  const {
    mainBanner: {
      childImageSharp: { fluid: mainBannerFluidImage },
    },
    websiteImage: {
      childImageSharp: { fluid: websiteImage },
    },
    ecommerceImage: {
      childImageSharp: { fluid: ecommerceImage },
    },
    marketingImage: {
      childImageSharp: { fluid: marketingImage },
    },
    contactFormImage: {
      childImageSharp: { fluid: contactFormImage },
    },
  } = useStaticQuery(images)

  return (
    <Layout>
      <SEO title="Websites, Lojas Virtuais, Marketing, Mídias Sociais" />

      <Hero
        title="Contrate a Paladino \ne Expanda seu negócio."
        button={{
          text: "ENTRAR EM CONTATO",
          color: "primary",
          link: "/#contato",
        }}
        fluidImage={mainBannerFluidImage}
      />

      <SelectIconSection
        description="QUEM SERVIMOS"
        elements={data.quemservimos}
      />

      <FeatureSideImageSection
        title="Website"
        description="Em um mundo digitalizado, possuir um website tornou-se fundamental para qualquer empresa. Na nossa abordagem, cada site é criado com foco em otimizar a conversão. Entregamos designs profissionais, atraentes e contemporâneos, sempre alinhados às tecnologias mais recentes do mercado."
        buttonText="conhecer serviço"
        buttonLink="/servicos/website"
        image={websiteImage}
        floatImage
        mobileReverse
      />

      <FeatureSideImageSection
        title="Loja Virtual"
        description="Com o constante crescimento do faturamento das vendas online no Brasil, é vital marcar presença neste mercado em expansão. Estamos aqui para auxiliar nessa jornada, criando para você uma loja virtual robusta, totalmente automatizada e com foco em alta conversão."
        buttonText="conhecer serviço"
        buttonLink="/servicos/loja-virtual"
        image={ecommerceImage}
        reverse
        alternativeBg
        floatImage
        mobileReverse
      />

      <FeatureSideImageSection
        title="Sistemas e Integrações"
        description="Ter sistemas é essencial para controlar processos e reduzir custos de uma empresa. Por isso, nossa agência desenvolve sistemas customizados e realiza integrações entre sistemas existentes. Conte conosco para otimizar seus processos e reduzir seus custos operacionais."
        buttonText="conhecer serviço"
        buttonLink="/servicos/marketing"
        image={marketingImage}
        floatImage
        mobileReverse
      />

      <LogoCompaniesSection />

      <ContactSection
        id="contato"
        fluidImage={contactFormImage}
        phoneNumberLink="tel:027996433420"
        whatsappNumberLink="https://api.whatsapp.com/send?phone=+5527996433420&text=Ol%C3%A1.%20Eu%20tenho%20interesse%20nos%20Servi%C3%A7os%20da%20Ag%C3%AAncia."
      />
    </Layout>
  )
}

const images = graphql`
query {
  mainBanner: file(relativePath: { eq: "banners/main-banner.jpg" }) {
    childImageSharp {
      fluid(base64Width: 1000, quality: 50) {
        base64
      }
    }
  }
  websiteImage: file(
    relativePath: { eq: "card-images/card-image-website.png" }
  ) {
    childImageSharp {
      fluid(maxWidth: 1024) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  ecommerceImage: file(
    relativePath: { eq: "card-images/card-image-ecommerce.png" }
  ) {
    childImageSharp {
      fluid(maxWidth: 1024) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  marketingImage: file(
    relativePath: { eq: "card-images/card-image-marketing.png" }
  ) {
    childImageSharp {
      fluid(maxWidth: 1024) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  contactFormImage: file(
    relativePath: { eq: "banners/contact-banner.jpg" }
  ) {
    childImageSharp {
      fluid(maxHeight: 1350) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
}
`

export default IndexPage
