import React from "react"

import { Container } from "./styles"

interface IconTextProps {
  text: string
}

const IconText: React.FC<IconTextProps> = ({ children, text, ...rest }) => {
  return (
    <Container {...rest}>
      {children}
      <p>{text}</p>
    </Container>
  )
}

export default IconText
