import React from "react"

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>25-shopping</title>
    <g id="_25-shopping" data-name="25-shopping">
      <path d="M45,40H26a1,1,0,0,1-.988-.844L22.146,21H19V19h4a1,1,0,0,1,.988.844L26.854,38H45Z"></path>
      <path d="M45,36H26V34H44.153l1.666-10H23V22H47a1,1,0,0,1,.986,1.164l-2,12A1,1,0,0,1,45,36Z"></path>
      <path d="M28,48a3,3,0,1,1,3-3A3,3,0,0,1,28,48Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,28,44Z"></path>
      <path d="M43,48a3,3,0,1,1,3-3A3,3,0,0,1,43,48Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,43,44Z"></path>
      <path d="M23,48H1a1,1,0,0,1-1-1V9A1,1,0,0,1,1,8H31a1,1,0,0,1,1,1V20H30V10H2V46H23Z"></path>
      <path d="M26,13H24V10A8,8,0,0,0,8,10v3H6V10a10,10,0,0,1,20,0Z"></path>
      <path d="M7,18a3,3,0,1,1,3-3A3,3,0,0,1,7,18Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,7,14Z"></path>
      <path d="M25,18a3,3,0,1,1,3-3A3,3,0,0,1,25,18Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,25,14Z"></path>
      <rect x="11" y="25" width="9" height="2"></rect>
      <rect x="13" y="30" width="7" height="2"></rect>
      <rect x="15" y="35" width="5" height="2"></rect>
      <rect x="7" y="25" width="2" height="2"></rect>
      <rect x="9" y="30" width="2" height="2"></rect>
      <rect x="11" y="35" width="2" height="2"></rect>
    </g>
  </svg>
)
